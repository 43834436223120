<template>
  <div class="box-image-area section-space--pt_120 section-space--pb_20 theme_bg-2 py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title text-center">
            <h3 class="section-title text-center mb-4">Features</h3>
          </div>
        </div>
      </div>
      <b-tabs fill small nav-class="tabs-scroll" id="tabs" :value="activeTab">
        <b-tab id="tab-0" title="Nerd Search<">
          <template #title>
            <div class="d-flex flex-column align-items-center">
              <BIconMap class="my-2 heading-features-color" font-scale="2" />
              <p class="heading-features">Nerd Search</p>
            </div>
          </template>
          <b-card-text>
            <div>
              <img class="features-img"
                src="../assets/img/rx-features/NerdSearch.webp"
                alt="Nerd Search" />
            </div>
          </b-card-text>
        </b-tab>

        <b-tab id="tab-1" title="RadixSearch">
          <template #title>
            <div class="d-flex flex-column align-items-center">
              <BIconSearch class="my-2 heading-features-color" font-scale="2" />
              <p class="heading-features">RadixSearch</p>
            </div>
          </template>
          <b-card-text>
            <div>
              <img class="features-img"
                src="../assets/img/rx-features/RadixSearch.webp"
                alt="RadixSearch" />
            </div>
          </b-card-text>
        </b-tab>

        <b-tab id="tab-2" title="Assay Finder">
          <template #title>
            <div class="d-flex flex-column align-items-center">
              <BIconClipboardData class="my-2 heading-features-color" font-scale="2" />
              <p class="heading-features">Assay Finder</p>
            </div>
          </template>
          <b-card-text>
            <div>
              <img class="features-img"
                src="../assets/img/rx-features/AssayFinder.webp"
                alt="Assay Finder" />
            </div>
          </b-card-text>
        </b-tab>
        

        <b-tab id="tab-3" title="RadixSuggest">
          <template #title>
            <div class="d-flex flex-column align-items-center">
              <BIconBook class="my-2 heading-features-color" font-scale="2" />
              <p class="heading-features">RadixSuggest</p>
            </div>
          </template>
          <b-card-text>
            <div>
              <img class="features-img"
                src="../assets/img/rx-features/NerdSearch.webp"
                alt="RadixSearch" />
            </div>
          </b-card-text>
        </b-tab>

        <b-tab id="tab-4" title="RadixEye">
          <template #title>
            <div class="d-flex flex-column align-items-center">
              <BIconEye class="my-2 heading-features-color" font-scale="2" />
              <p class="heading-features">RadixEye</p>
            </div>
          </template>
          <b-card-text>
            <div>
              <img class="features-img"
                src="../assets/img/rx-features/RadixSearch.webp"
                alt="RadixSearch" />
            </div>
          </b-card-text>
        </b-tab>

        <b-tab id="tab-5" title="RadixGPT">
          <template #title>
            <div class="d-flex flex-column align-items-center">
              <BIconPencil class="my-2 heading-features-color" font-scale="2" />
              <p class="heading-features">RadixGPT</p>
            </div>
          </template>
          <b-card-text>
            <div>
              <img class="features-img"
                src="../assets/img/rx-features/AssayFinder.webp"
                alt="RadixSearch" />
            </div>
          </b-card-text>
        </b-tab>

        <b-tab id="tab-6" title="RadixFinance">
          <template #title>
            <div class="d-flex flex-column align-items-center">
              <BIconGraphUp class="my-2 heading-features-color" font-scale="2" />
              <p class="heading-features">RadixFinance</p>
            </div>
          </template>
          <b-card-text>
            <div>
              <img class="features-img"
                src="../assets/img/rx-features/NerdSearch.webp"
                alt="RadixSearch" />
            </div>
          </b-card-text>
        </b-tab>

        <b-tab id="tab-7" title="RadixExtract">
          <template #title>
            <div class="d-flex flex-column align-items-center">
              <BIconGrid class="my-2 heading-features-color" font-scale="2" />
              <p class="heading-features">RadixExtract</p>
            </div>
          </template>
          <b-card-text>
            <div>
              <img class="features-img"
                src="../assets/img/rx-features/RadixSearch.webp"
                alt="RadixSearch" />
            </div>
          </b-card-text>
        </b-tab>

        <b-tab id="tab-8" title="Geospatial Visualizer">
          <template #title>
            <div class="d-flex flex-column align-items-center">
              <BIconGlobe2 class="my-2 heading-features-color" font-scale="2" />
              <p class="heading-features">Geospatial<br /> Visualizer</p>
            </div>
          </template>
          <b-card-text>
            <div>
              <img class="features-img"
                src="../assets/img/rx-features/AssayFinder.webp"
                alt="RadixSearch" />
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  data() {
    return {
      activeTab: 0, // Default active tab
      tabs: [], // Store tab components
    };
  },
  created() {
    // Register child tabs
    this.tabs = this.$children;
  },
  watch: {
    // Watch for changes in the query parameter and update active tab
    "$route.query.tabId"(newTabId) {
      this.updateActiveTab(newTabId);
    },
  },
  mounted() {
    // Set the initial active tab based on the URL query parameter
    this.updateActiveTab(this.$route.query.tabId);
  },
  methods: {
    updateActiveTab(tabId) {
      const tabIndex = parseInt(tabId, 10);
      if (typeof tabIndex === 'number') {
        this.activeTab = tabIndex;
      } else {
        this.activeTab = 0;
      }
    },
    setActiveTab(index) {
      this.activeTab = index;
      this.$router.push({ query: { tabId: index } });
    },
  },
};
</script>

<style>
.tabs-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.heading-features {
  color: #6d70a6;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1rem;
}

.heading-features-color {
  color: #6d70a6;
}

.features-img {
  margin-top: 20px;
  border-radius: 20px;
  width: 100%;
  padding: 10px;
  background-color: white;
  border: solid 1px lightgrey;
}
</style>